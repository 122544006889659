import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  DestroyRef,
  EventEmitter,
  Inject,
  Injector,
  OnDestroy,
  OnInit,
  Output,
  PLATFORM_ID
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { ActivatedRoute, RouterLink } from '@angular/router';

import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { skip } from 'rxjs/operators';
import { UserRole } from 'src/definitions/user-role.enum';
import { GoogleTagEventType, TrackingService } from 'src/services/tracking.service';
import { LoadingStore } from 'src/stores/loading.store';

import { AuthService } from './auth/auth.service';

/**
 * Selector, template-form and styles for login component
 */
@Component({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  selector: 'app-ng-login',
  styleUrls: ['./login.component.scss'],
  templateUrl: './login.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    RouterLink,
    TranslateModule
  ]
})

/**
 * Login component
 */
export class LoginComponent implements OnInit, OnDestroy {
  /**
   * Login form
   */
  public loginForm: FormGroup;

  /**
   * Indicator that the component is destroyed
   * @private
   */
  private destroyed = new Subject();

  /**
   * The user role
   */
  role: UserRole;

  /**
   * Value to show an icon or text for password field
   */
  showPassword = false;

  /**
   * Event emitter that will trigger an event when the login  is correct.
   */
  @Output() logged: EventEmitter<string | null> = new EventEmitter();

  translateService: TranslateService | undefined;

  /**
   * The user role enum
   */
  roleEnum = UserRole;

  /**
   * Component constructor
   */
  constructor(
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    @Inject(PLATFORM_ID) private platformId: object,
    private authService: AuthService,
    private inj: Injector,
    public loading: LoadingStore,
    private route: ActivatedRoute,
    private destroyRef: DestroyRef,
    private trackingService: TrackingService
  ) {
    this.loginForm = new FormGroup({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
    /*TODO: Add the implementation for clients*/
    this.role = UserRole.OPERATOR;
  }
  /**
   * Creates the login form fields and their validations
   */
  ngOnInit(): void {
    this.translateService = this.inj.get(TranslateService);

    // If already logged go to home
    if (this.authService.loggedIn()) {
      this.logged.emit(null);
    }

    this.route.data.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((data) => {
      this.role = data.role;
    });
  }

  /**
   * Method called on the event 'onSubmit', if an access_token is received then navigates to home otherwise shows
   * the error
   */
  public login(): void {
    this.loading.start();
    this.loginForm.disable();

    this.authService
      .login(this.loginForm.value, this.role)
      .pipe(skip(1))
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((loginOk: boolean) => {
        this.authService.successLoginInfoAndRedirect(loginOk, this.role);
        this.trackingService.pushTagEvent({
          event: GoogleTagEventType.Login,
          userId: AuthService.getUserData()?.id
        });
        this.loading.stop();
        this.loginForm.enable();
      })
      .add(() => {
        this.loading.stop();
        this.loginForm.enable();
      });
  }

  /**
   * Method to show or hide the password
   * @param event
   */
  togglePasswordVisibility(event: MouseEvent): void {
    this.showPassword = !this.showPassword;
    if (event && isPlatformBrowser(this.platformId)) {
      event.stopImmediatePropagation();
    }
  }

  ngOnDestroy(): void {
    this.destroyed.next(null);
    this.destroyed.complete();
  }
}
